<template>
    
</template>

<script lang="js">
    export default {
        name: 'DossieAgricola', 
        data() {
           
        },
       
    }
    
</script>