<template>
    <div v-if="!mostraProjetoDetalhado" >
        <div class="card">
            <h5>Dossiê Agrícola</h5>
    
            <div class="formgrid grid">
                <div class="field col-6 md:col-2">
                    <label for="data-inicial">Safra</label>
                    <AutoComplete
                        :dropdown="true"
                        v-model="safraSelecionada" 
                        :suggestions="safrasFiltradas" 
                        @complete="buscarSafra($event)"
                        @item-select="buscarInformacoesDossie"
                        field="descricao"
                        class="w-full"
                    />
                </div>
    
                <div class="field col-6 md:col-2">
                    <label for="data-final">Unidade de medida</label>
                    <AutoComplete
                        :dropdown="true"
                        v-model="unidadeSelecionada" 
                        :suggestions="unidadesFiltradas" 
                        @complete="buscarUnidade($event)"
                        @item-select="buscarInformacoesDossie"
                        class="w-full"
                    />
                </div>
    
                <div class="field col-12 md:col-2">
                    Projetos
                    <p style="font-size: 24px; font-weight: bold;" class="mt-1 pt-2 pl-2">
                        {{ qtdProjeto }}
                    </p>
                </div>
    
                <div class="field col-12 md:col-3">
                    Produção prevista
                    <div class="flex align-items-center mt-1 pt-2 pl-2">
                        <img src="../../../public/binoculos.png" width="35" class="mr-2" />
                        <strong style="font-size: 20px;">
                            {{ totalPrevisto }}
                        </strong>
                    </div>
                </div>
    
                <div class="field col-12 md:col-3">
                    Produção obtida
                    <div class="flex align-items-center mt-1 pt-2 pl-2">
                        <img src="../../../public/Wheat_Flour.png" width="35" class="mr-2" />
                        <strong style="font-size: 20px;">
                            {{ totalObtido }}
                        </strong>
                    </div>
                </div>
            </div>
    
            <div class="grid">
                <div class="col-12 md:col-6 mt-3">
                    <div class="flex align-items-center">
                        <img src="../../../public/charts.png" width="50" class="mr-2" />
    
                        <div>
                            <h5 class="mb-1">Produtividade dos imóveis (kg)</h5>
                        </div>
                    </div>
                    <Chart v-if="grafico != null" type="bar" :data="grafico" />
                    <h4 v-if="grafico == null" style="text-align:center;">Produtividade ainda não foi lançada.</h4>
                </div>
    
                <div class="col-12 md:col-6 mt-3">
                    <div class="flex align-items-center">
                        <img src="../../../public/atendimentos.png" width="50" class="mr-2" />
    
                        <div>
                            <h5 class="mb-1">{{ qtdAtendimentos }} atendimentos realizados</h5>
                        </div>
                    </div>
                    <Atendimentos 
                        :matricula="currentUser.matricula"
                        :dataInicio="getDataSafraInicio()"
                        :dataFim="getDataSafraFim()"
                        :paginacao="true"
                        :qtdLinhas="4"
                        @qtdAtendimento="setQtdAtendimento"
                    />
                </div>
            </div>
        </div>
    
        <ProjetosAgricolas
            :matricula="currentUser.matricula"
            :safra="safraSelecionada ? safraSelecionada.safraComposta : ''"
            :unidadeMedida='unidadeSelecionada'
            @qtdResumoGeral="setQtdResumoGeral"
            @setDetalharArea="setDetalharArea"
        />
    </div>

    <div v-if="mostraProjetoDetalhado">
        <ProjetosAgricolasDetalhado 
            v-if="isProjetoDetalhado"
            :resumoGeral='detalharArea'
            :unidadeMedida='unidadeSelecionada' 
            @mostrarProjetoDetalhado='mostrarProjetoDetalhado' 
        />
    </div>
</template>

<script lang="js">
    import DossieAgricola from '../../components/consulta/DossieAgricola.vue'
    import Atendimentos from '../../components/consulta/Atendimentos.vue';
    import ProjetosAgricolas from '../../components/consulta/ProjetosAgricolas.vue';
    import ProjetosAgricolasDetalhado from '../../components/consulta/ProjetosAgricolasDetalhado.vue';
    import SafrasService from '../../service/SafraService';
    import DossieAgriculaService from '../../service/DossieAgriculaService';
    import Formatacao from '../../utilities/Formatacao';
    import moment from 'moment';

    export default {
        components: { DossieAgricola, Atendimentos, ProjetosAgricolas, ProjetosAgricolasDetalhado },
        data() {
            return {
                safras: [],
                safraSelecionada: null,
                safrasFiltradas: [],

                unidades: [],
                unidadeSelecionada: '',
                unidadesFiltradas: [],

                qtdProjeto: 0,
                qtdAtendimentos: 0,
                totalObtido: '0,00 Kg',
                totalPrevisto: '0,00 Kg',

                isProjetoDetalhado: false,
                detalharArea: null,

                grafico: null,
                mostraProjetoDetalhado: false,
            }
        },

        mounted() {
            SafrasService.getSafras().then(({ data }) => {
                if(data) {
                    this.safras = data.sort((func1, func2) => {
                        let descricao1 = func1.descricao;
                        let descricao2 = func2.descricao;

                        return descricao1 == descricao2 ? 0 : descricao1 < descricao2 ? 1 : -1;
                    });

                    if(this.safras && this.safras.length > 1) {
                        this.safraSelecionada = this.safras[0];
                    }
                }
            });

            DossieAgriculaService.getUnidades().then(({ data }) => {
                if(data) {
                    this.unidades = data.sort((descricao1, descricao2) => {
                        return descricao1 == descricao2 ? 0 : descricao1 > descricao2 ? 1 : -1;
                    });

                    if(this.unidades && this.unidades.length > 1) {
                        this.unidadeSelecionada = this.unidades[0];
                    }
                }
            });
        },

        computed: {
			currentUser() {
				return this.getCurrentUser();
			}
		},

        methods: {
            buscarSafra(event) {
                setTimeout(() => {
					if (!event.query.trim().length) {
						this.safrasFiltradas = [...this.safras];
					}
					else {
						this.safrasFiltradas = this.safras.filter((item) => {
							return item.safra.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
				}, 250);
            },

            buscarUnidade(event) {
                setTimeout(() => {
					if (!event.query.trim().length) {
						this.unidadesFiltradas = [...this.unidades];
					}
					else {
						this.unidadesFiltradas = this.unidades.filter((item) => {
							return item.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
				}, 250);
            },

            buscarInformacoesDossie() {
                if(!this.safraSelecionada) return;
                let matricula = this.getCurrentUser().matricula;
                
                DossieAgriculaService.getInformacoesDossie(this.safraSelecionada.safraComposta, matricula)
                    .then(({ data }) => {
                        if(data) {
                            this.totalObtido = Formatacao.formatDecimal(data.totalObtido) + ' Kg';
                            this.totalPrevisto = Formatacao.formatDecimal(data.totalPrevisto) + ' Kg';

                            this.grafico = this.criarGrafico(data.graficos);
                        }
                        else {
                            this.totalObtido = '0,00 Kg';
                            this.totalPrevisto = '0,00 Kg';
                            this.grafico = null;
                        }
                    })
                    .catch(error => {
                        this.totalObtido = '0,00 Kg';
                        this.totalPrevisto = '0,00 Kg';
                        this.grafico = null;
                    });
            },

            getDataSafraInicio() {
                if(!this.safraSelecionada) {
                    return '';
                }

                return this.safraSelecionada.dataInicio.split('-').reverse().join('-');
            },

            getDataSafraFim() {
                if(!this.safraSelecionada) {
                    return '';
                }

                const dataInicio = moment(this.safraSelecionada.dataInicio, 'YYYY-MM-DD');
                const dataFim = moment(this.safraSelecionada.dataFim, 'YYYY-MM-DD');

                if(dataInicio.isAfter(dataFim)) {
                    return moment().format('DD-MM-YYYY');
                }

                return dataFim.format('DD-MM-YYYY');
            },

            setQtdResumoGeral(quantidade) {
                this.qtdProjeto = quantidade;
            },

            setQtdAtendimento(quantidade) {
                this.qtdAtendimentos = quantidade;
            },

            setDetalharArea(dados) {
                if(!dados) {
                    this.isProjetoDetalhado = false;
                    this.detalheArea = null;
                }

                this.isProjetoDetalhado = true;
                this.detalharArea = dados;

                this.mostrarProjetoDetalhado();
            },

            criarGrafico(dados) {
                const isLight = this.$appState.layoutMode === 'light';
                const realizado = isLight ? '#00ACC1' : '#4DD0E1';
                const previsto = isLight ? '#FF9800' : '#FFB74D';

                const propriedades = [];
                const previsoes = [];
                const realizados = [];

                for(let item of dados) {
                    propriedades.push(item.propriedade);
                    previsoes.push(item.previsao ? item.previsao : 0);
                    realizados.push(item.obtida ? item.obtida : 0);
                }

                return {
                    labels: propriedades,
                    datasets: [
                        {
                            label: 'Previsto',
                            backgroundColor: previsto,
                            borderColor: previsto,
                            data: previsoes
                        },
                        {
                            label: 'Realizado',
                            backgroundColor: realizado,
                            borderColor: realizado,
                            data: realizados
                        }
                    ]
                };
            },

            getCurrentUser() {
                return this.$store.state.auth.user;
            },

            mostrarProjetoDetalhado() {
                this.mostraProjetoDetalhado = !this.mostraProjetoDetalhado;
            }
        }
    }

</script>