<template>
    <div ref="map" class="map"></div>
</template>

<script>
    import { loadScript } from "vue-plugin-load-script";

    export default {
        name: 'DossieMapa',
        props: {
            dados: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                center: {
                    lat: 0,
                    lng: 0
                },
                polygons: []
            }
        },

        mounted() {
            this.prepararDados();
            this.renderizarMapa(this.$refs.map, this.center, this.polygons);
        },

        methods: {
            prepararDados() {
                if(!this.dados) return;

                this.polygons = this.dados.listaPontoGps
                    .sort((itemA, itemB) => {
                        return itemA.ordem - itemB.ordem;
                    })
                    .map((item) => {
                        return {
                            lat: item.latitude,
                            lng: item.longitude
                        }
                    });

                const posicaoCentral = this.dados.posicaoCentral ? this.dados.posicaoCentral.split(',') : [0, 0];
                if(posicaoCentral && posicaoCentral.length > 1) {
                    this.center = {
                        lat: parseFloat(posicaoCentral[0]),
                        lng: parseFloat(posicaoCentral[1])
                    }
                }
            },

            renderizarMapa(element, center, polygons) {
                loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyAYXXzQynO3JPRR_4twi1Sbp9q2z_YcyU0")
                    .then(() => {
                        const map = new google.maps.Map(element, {
                            zoom: 14,
                            center: center,
                            mapTypeId: "satellite"
                        });

                        const polygon = new google.maps.Polygon({
                            paths: polygons,
                            strokeColor: "#FF9900",
                            strokeOpacity: 0.7,
                            fillOpacity: 0.7,
                            fillColor: "#FF9900",
                        });

                        polygon.setMap(map);
                    })
                    .catch(() => {
                        console.error("Erro ao carregar script do google maps!");
                    });
            },
        }
    }
</script>

<style scoped>
    .map {
        height: 400px;
        width: 100%;
    }
</style>
