import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1`;

class AtendimentoService {
    getServicos(matricula, dataInicio, dataFim) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/atendimento-servico/" + matricula + "/" + dataInicio + "/" + dataFim);
    }

    getOcorrencias(matricula, dataInicio, dataFim) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/atendimento-ocorrencia/" + matricula + "/" + dataInicio + "/" + dataFim);
    }

    getRecomendacoes(matricula, dataInicio, dataFim) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/atendimento-recomendacao/" + matricula + "/" + dataInicio + "/" + dataFim);
    }

    getAplicados(matricula, dataInicio, dataFim) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/atendimento-aplicacao/" + matricula + "/" + dataInicio + "/" + dataFim);
    }

    getImagensPorIdAtendimento(idAtendimento) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/atendimento-imagem/" + idAtendimento);
    }
}

export default new AtendimentoService();