<template>
    <div class="card">
        <Button label="Voltar" 
                class="p-button-raised p-button-success mb-5" 
                icon="pi pi-chevron-left" 
                v-on:click="$emit('mostrarProjetoDetalhado')" />
        <div class="flex align-items-center">
            <img src="../../../public/fazenda.jpg" width="65" class="mr-2" />

            <div>
                <h5 class="mb-1">DOSSIÊ - {{ resumoGeral.safra }} - {{ resumoGeral.nomePropriedade }}</h5>
                <span><strong>Código:</strong> {{ resumoGeral.idProjetoAgricola }}</span>
            </div>
        </div>

        <div class="grid projetos-agricolas-detalhado mt-3">
            <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                <strong>CULTURA:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-4 xl:col-6 detalhes">
                {{ resumoGeral.cultura }}
            </div>
            
            <div class="col-4 sm:col-4 md:col-1 xl:col-1 detalhes">
                <strong>SAFRA:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-2 xl:col-2 detalhes">
                {{ resumoGeral.safra }}
            </div>
            
            <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                <strong>SITUAÇÃO:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-1 xl:col-1 detalhes">
                {{ resumoGeral.situacaoSafra }}
            </div>

            <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                <strong>COOPERADO:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-10 xl:col-11 detalhes">
                {{ resumoGeral.nomeCooperado }}
            </div>

            <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                <strong>IMÓVEL:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-10 xl:col-11 detalhes">
                {{ resumoGeral.nomePropriedade }}
            </div>

            <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                <strong>ÁREA:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-4 xl:col-3 detalhes">
                {{ formatDecimal((this.unidadeMedida === 'ALQUEIRE' ? resumoGeral.alqueire : resumoGeral.tamanho)) }} {{ getDescricaoUnidadeMedida() }}
            </div>

            <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                <strong>PREVISTO:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-4 xl:col-2 detalhes">
                {{ getDivisaoPorScOuKg(resumoGeral.previsao) }}
            </div>

            <div class="col-4 sm:col-4 md:col-3 xl:col-2 detalhes">
                <strong>PREVISTO / ÁREA:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-3 xl:col-3 detalhes">
                {{ getCalculoPorArea(resumoGeral.previsao, (this.unidadeMedida === 'ALQUEIRE' ? resumoGeral.alqueire : resumoGeral.tamanho)) }}
            </div>

            <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                <strong>OBTIDA:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-4 xl:col-3 detalhes">
                {{ getDivisaoPorScOuKg(resumoGeral.obtida) }}
            </div>

            <div class="col-4 sm:col-4 md:col-3 xl:col-2 detalhes">
                <strong>OBTIDA / ÁREA:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-3 xl:col-2 detalhes">
                {{ getCalculoPorArea(resumoGeral.obtida, (this.unidadeMedida === 'ALQUEIRE' ? resumoGeral.alqueire : resumoGeral.tamanho)) }}
            </div>

            <div class="col-4 sm:col-4 md:col-2 xl:col-2 detalhes">
                <strong>INVESTIMENTO:</strong>
            </div>

            <div class="col-8 sm:col-8 md:col-4 xl:col-2 detalhes">
                {{ getInvestimento(resumoGeral) }} / {{ unidadeMedida == 'ALQUEIRE' ?  'ALQUEIRE' : 'HA' }}
            </div>
        </div>

        <Accordion class="mt-3">
            <AccordionTab v-for="item in resumoArea" :key="item.idProjetoAgricola">

                <template #header>
                    {{ item.area?.toUpperCase() }}
                </template>

                <div class="grid projetos-agricolas-detalhado mt-3">
                    <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                        <strong>VARIEDADE:</strong>
                    </div>
                    <div class="col-8 sm:col-8 md:col-4 xl:col-3 detalhes">
                        {{ item.variedade }}
                    </div>
                    
                    <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                        <strong>ESTÁGIO:</strong>
                    </div>
                    <div class="col-8 sm:col-8 md:col-4 xl:col-2 detalhes">
                        {{ item.estagio }}
                    </div>
                    
                    <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                        <strong>TAMANHO:</strong>
                    </div>
                    <div class="col-8 sm:col-8 md:col-2 xl:col-2 detalhes">
                        {{ formatDecimal((this.unidadeMedida === 'ALQUEIRE' ? item.alqueire : item.tamanho)) }} {{ getDescricaoUnidadeMedida() }} 
                    </div>
                    
                    <div class="col-4 sm:col-5 md:col-2 xl:col-1 detalhes">
                        <strong>NÍVEL:</strong>
                    </div>
                    <div class="col-8 sm:col-8 md:col-1 xl:col-1 detalhes">
                        {{ item.nivel }}
                    </div>
                    
                    <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                        <strong>PREVISÃO / ÁREA:</strong>
                    </div>
                    <div class="col-8 sm:col-8 md:col-10 xl:col-11 detalhes">
                        {{ getCalculoPorArea(item.previsao, (this.unidadeMedida === 'ALQUEIRE' ? item.alqueire : item.tamanho)) }}
                    </div>
                    
                    <div class="col-4 sm:col-4 md:col-2 xl:col-1 detalhes">
                        <strong>OBTIDA / ÁREA:</strong>
                    </div>
                    <div class="col-8 sm:col-8 md:col-10 xl:col-11 detalhes">
                        {{ getCalculoPorArea(item.obtida, (this.unidadeMedida === 'ALQUEIRE' ? item.alqueire : item.tamanho)) }}
                    </div>
                </div>

                <hr />
                <DossieMapa :dados="item" />
                <hr />

                <!-- INICIO TABELA OCORRENCIAS -->
                <div class="flex align-items-center justify-content-between">
                    <h5 style="font-size: 17px;">{{ item.todasOcorrencias.length }} ocorrência(s)</h5>
                    <Button 
                        type="button" 
                        icon="pi pi-file-excel" 
                        label="Exportar" 
                        class="p-button-warning" 
                        @click="exportarOcorrencia(item.todasOcorrencias)" 
                        :disabled="item.todasOcorrencias.length <= 0"
                    />
                </div>

                <DataTable :value="item.todasOcorrencias" responsiveLayout="scroll">
                    <template #empty>
                        Nenhuma ocorrência encontrada.
                    </template>

                    <Column field="data" header="Data" :sortable="true" style="width:1rem;" />
                    <Column field="estagio" header="Estágio" :sortable="true" style="min-width:8rem; max-width:10rem;" />
                    <Column field="ocorrencia" header="Ocorrência" :sortable="true" style="min-width:8rem; max-width:15rem;" />
                    <Column field="comprometimento" header="Comprometimento" :sortable="true" style="min-width:2rem; max-width:15rem;" />
                    <Column field="quebra" header="Quebra" :sortable="true" style="min-width:2rem; max-width:10rem;" />
                    <Column header="Fotos" :sortable="false" style="width:1rem;">
                        <template #body="{ data }">
                            <Button 
                                title="Fotos" 
                                icon="pi pi-images" 
                                class="p-button-rounded p-button-secondary p-button-outlined"
                                :disabled="!data.possuiimagem"
                                @click="abrirGaleriaOcorrencia(data.idOcorrenciaIdentificada)"
                            />
                        </template>
                    </Column>
                </DataTable>
                <!-- FIM TABELA OCORRENCIAS -->

                <br />

                <!-- INICIO TABELA RECOMENDADOS -->
                <div class="flex align-items-center justify-content-between">
                    <h5 style="font-size: 17px;">{{ item.todosProdutosRecomendados.length }} produto(s) recomendado(s)</h5>
                    <Button 
                        type="button" 
                        icon="pi pi-file-excel" 
                        label="Exportar" 
                        class="p-button-warning" 
                        @click="exportarRecomendado(item.todosProdutosRecomendados)" 
                        :disabled="item.todosProdutosRecomendados.length <= 0"
                    />
                </div>

                <DataTable :value="item.todosProdutosRecomendados" responsiveLayout="scroll">
                    <template #empty>
                        Nenhum produto recomendado.
                    </template>

                    <Column field="data" header="Data" :sortable="true" style="width:1rem;" />
                    <Column field="origem" header="Origem" :sortable="true" style="min-width:8rem; max-width:10rem;" />
                    <Column field="ocorrencia" header="Ocorrência" :sortable="true" style="min-width:8rem; max-width:15rem;" />
                    <Column field="produto" header="Produto" :sortable="true" style="min-width:2rem; max-width:15rem;" />
                    <Column field="area" header="Área" :sortable="true" style="min-width:2rem; max-width:10rem;" />
                    <Column field="dosagem" header="Dosagem" :sortable="true" style="min-width:2rem; max-width:10rem;" />
                </DataTable>
                <!-- FIM TABELA RECOMENDADOS -->

                <br />

                <!-- INICIO TABELA APLICADOS -->
                <div class="flex align-items-center justify-content-between">
                    <h5 style="font-size: 17px;">{{ item.todosProdutosAplicados.length }} produto(s) aplicados(s)</h5>
                    <Button 
                        type="button" 
                        icon="pi pi-file-excel" 
                        label="Exportar" 
                        class="p-button-warning" 
                        @click="exportarAplicado(item.todosProdutosAplicados)" 
                        :disabled="item.todosProdutosAplicados.length <= 0"
                    />
                </div>

                <DataTable :value="item.todosProdutosAplicados" responsiveLayout="scroll">
                    <template #empty>
                        Nenhum produto aplicado.
                    </template>

                    <Column field="data" header="Data" :sortable="true" style="width:1rem;" />
                    <Column field="origem" header="Origem" :sortable="true" style="min-width:8rem; max-width:10rem;" />
                    <Column field="produto" header="Produto" :sortable="true" style="min-width:2rem; max-width:15rem;" />
                    <Column field="area" header="Área" :sortable="true" style="min-width:2rem; max-width:10rem;" />
                    <Column field="dosagem" header="Dosagem" :sortable="true" style="min-width:2rem; max-width:10rem;" />
                    <Column field="aplicacao" header="Aplicação" :sortable="true" style="min-width:8rem; max-width:15rem;" />
                    <Column field="profissional" header="Profissional" :sortable="true" style="min-width:8rem; max-width:15rem;" />
                </DataTable>
                <!-- FIM TABELA APLICADOS -->

                <br />

                <!-- INICIO TABELA SERVIÇOS -->
                <div class="flex align-items-center justify-content-between">
                    <h5 style="font-size: 17px;">{{ item.todosServicosRealizados.length }} serviço(s)</h5>
                    <Button 
                        type="button" 
                        icon="pi pi-file-excel" 
                        label="Exportar" 
                        class="p-button-warning" 
                        @click="exportarServico(item.todosServicosRealizados)" 
                        :disabled="item.todosServicosRealizados.length <= 0"
                    />
                </div>

                <DataTable :value="item.todosServicosRealizados" responsiveLayout="scroll">
                    <template #empty>
                        Nenhum serviço realizado.
                    </template>

                    <Column field="data" header="Data" :sortable="true" style="width:1rem;" />
                    <Column field="estagio" header="Estágio" :sortable="true" style="min-width:8rem; max-width:10rem;" />
                    <Column field="tiposervico" header="Tipo serviço" :sortable="true" style="min-width:2rem; max-width:15rem;" />
                    <Column field="servico" header="Serviço" :sortable="true" style="min-width:2rem; max-width:10rem;" />
                    <Column field="observacao" header="Observação" :sortable="true" style="min-width:2rem; max-width:10rem;" />
                    <Column header="Fotos" :sortable="false" style="width:1rem;">
                        <template #body="{ data }">
                            <Button 
                                title="Fotos" 
                                icon="pi pi-images" 
                                class="p-button-rounded p-button-secondary p-button-outlined"
                                :disabled="!data.possuiimagem"
                                @click="abrirGaleriaServico(data.idServicoRealizado)"
                            />
                        </template>
                    </Column>
                </DataTable>
                <!-- FIM TABELA SERVIÇOS -->
            </AccordionTab>
        </Accordion>
    </div>
    
    <Galleria :value="fotos" :responsiveOptions="responsiveOptions" :showIndicators="true"
              :numVisible="9" :circular="true" :fullScreen="true" :showItemNavigators="true"
              v-model:visible="galeria" :showThumbnails="false" containerStyle="max-width: 70%">
        <template #item="slotProps">
            <img :src="slotProps.item.imagem" :alt="slotProps.item.alt" style="width: 100%; display: block" />
        </template>
        <template #thumbnail="slotProps">
            <img :src="slotProps.item.thumbnail" :alt="slotProps.item.alt" style="width: 25%; display: block;" />
        </template>
    </Galleria>
</template>

<script lang="js">
    import DossieAgriculaService from '../../service/DossieAgriculaService';
    import DossieMapa from './DossieMapa.vue';
    import Formatacao from '../../utilities/Formatacao';
    import ExportarPlanilha from '../../utilities/ExportarPlanilha';

    export default {
        name: 'ProjetosAgricolasDetalhado',
        emits: ["qtdResumoGeral", "mostrarProjetoDetalhado"],
        components: { DossieMapa },
        props: {
            resumoGeral: {
                type: Object,
                required: true
            },
            unidadeMedida: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                resumoArea: [],
                ultimaProjetoAgricola: null,
                fotos: [],
                galeria: false,
                responsiveOptions: [
                    {
                        breakpoint: '1024px',
                        numVisible: 5
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 3
                    },
                    {
                        breakpoint: '560px',
                        numVisible: 1
                    }
                ],
            }
        },

        created() {
            this.atualizarResumoArea();
        },

        updated() {
            this.atualizarResumoArea();
        },

        methods: {
            atualizarResumoArea() {
                if(this.ultimaProjetoAgricola !== this.resumoGeral.idProjetoAgricola) {
                    this.ultimaProjetoAgricola = this.resumoGeral.idProjetoAgricola;

                    DossieAgriculaService.getResumoArea(this.ultimaProjetoAgricola)
                        .then(({ data }) => {
                            this.resumoArea = data ? data : [];
                        })
                        .catch(error => {
                            this.resumoArea = [];
                        });
                }
            },

            getDivisaoPorScOuKg(valor) {
                let retorno = 0;

                if(valor) {
                    retorno = (valor / this.resumoGeral.divisaoScOuKg);
                }

                retorno = Formatacao.formatDecimal(retorno) + ' ';
                retorno += this.resumoGeral.tituloScOuKg;

                return retorno;
            },

            getCalculoPorArea(valor, tamanho) {
                let retorno = 0;

                if(valor && tamanho) {
                    retorno = (valor / this.resumoGeral.divisaoScOuKg) / tamanho;
                }

                retorno = Formatacao.formatDecimal(retorno) + ' ';
                retorno += this.resumoGeral.tituloScOuKg;

                return retorno;
            },

            getInvestimento(obj) {
                let retorno = 0;

                if(obj && obj.investimento) {
                    retorno = this.converterUnidadeMedida(obj.investimento);
                }

                return Formatacao.formatCurrency(retorno);

            },

            converterUnidadeMedida(tamanho) {
                if (tamanho !== null) {
                    if (this.unidadeMedida === 'ALQUEIRE') {
                        return tamanho / 2.42;
                    }
                    else {
                        return tamanho;
                    }
                }

                return tamanho;
            },

            getDescricaoUnidadeMedida() {
                if(!this.unidadeMedida) return '';
                const descricao = this.unidadeMedida.toLowerCase();
                return descricao[0].toUpperCase() + descricao.substring(1, descricao.length) + '(s)';
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            exportarOcorrencia(ocorrencias) {
				const colunas = [
					{name: "idOcorrenciaIdentificada", title: "Nro"},
					{name: "data", title: "Data"},
					{name: "estagio", title: "Estágio"},
					{name: "ocorrencia", title: "Ocorrência"},
					{name: "comprometimento", title: "Comprometimento"},
					{name: "quebra", title: "Quebra"}
				];

				ExportarPlanilha({
					cols: colunas,
					rows: ocorrencias,
					types:{
						idOcorrenciaIdentificada: 'n'
					},
					filename: 'dossie_lista_ocorrencia.xls',
					bookType: 'biff8'
				});
			},

            exportarRecomendado(recomendados) {
				const colunas = [
					{name: "idProdutoRecomendado", title: "Nro"},
					{name: "origem", title: "Origem"},
					{name: "ocorrencia", title: "Ocorrência"},
					{name: "produto", title: "Produto"},
					{name: "area", title: "Área"},
					{name: "dosagem", title: "Dosagem"}
				];

				ExportarPlanilha({
					cols: colunas,
					rows: recomendados,
					types:{
						idProdutoRecomendado: 'n'
					},
					filename: 'dossie_lista_recomendados.xls',
					bookType: 'biff8'
				});
			},

            exportarAplicado(aplicados) {
				const colunas = [
					{name: "idProdutoAplicacao", title: "Nro"},
					{name: "data", title: "Data"},
					{name: "origem", title: "Origem"},
					{name: "produto", title: "Produto"},
					{name: "area", title: "Área"},
					{name: "dosagem", title: "Dosagem"},
					{name: "aplicacao", title: "Aplicação"},
					{name: "profissional", title: "Profissional"}
				];

				ExportarPlanilha({
					cols: colunas,
					rows: aplicados,
					types:{
						idProdutoAplicacao: 'n'
					},
					filename: 'dossie_lista_aplicados.xls',
					bookType: 'biff8'
				});
			},

            exportarServico(servicos) {
				const colunas = [
					{name: "idServicoRealizado", title: "Nro"},
					{name: "data", title: "Data"},
					{name: "estagio", title: "Estágio"},
					{name: "tiposervico", title: "Tipo serviço"},
					{name: "servico", title: "Serviço"},
					{name: "observacao", title: "Observação"}
				];

				ExportarPlanilha({
					cols: colunas,
					rows: servicos,
					types:{
						idServicoRealizado: 'n'
					},
					filename: 'dossie_lista_servicos.xls',
					bookType: 'biff8'
				});
			},

            abrirGaleriaServico(idServicoRealizado) {
                DossieAgriculaService.getImagensPorIdServicoRealizado(idServicoRealizado)
                    .then(({ data }) => {
                        const fotos = [];

                        for(let item of data) {
                            if(item.imagem){
                                fotos.push({
                                    thumbnail: "data:image/jpeg;base64," + item.imagem,
                                    imagem: "data:image/jpeg;base64," + item.imagem,
                                    alt: item.descricao
                                });
                            }
                        }

                        this.fotos = fotos;
                        this.galeria = true;
                    });
            },

            abrirGaleriaOcorrencia(idOcorrenciaIdentificada) {
                DossieAgriculaService.getImagensPorIdOcorrenciaIdentificada(idOcorrenciaIdentificada)
                    .then(({ data }) => {
                        const fotos = [];

                        for(let item of data) {
                            if(item.imagem){
                                fotos.push({
                                    thumbnail: "data:image/jpeg;base64," + item.imagem.imagem,
                                    imagem: "data:image/jpeg;base64," + item.imagem.imagem,
                                    alt: item.descricao
                                });
                            }
                        }

                        this.fotos = fotos;
                        this.galeria = true;
                    });
            }
        }
    }
</script>

<style>
    .projetos-agricolas-detalhado {
        background-color: #EBEBEB;
    }

    .projetos-agricolas-detalhado .detalhes {
        border-bottom: 1px dashed #BBB;
    }
</style>
