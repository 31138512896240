<template>
    <DataTable :value="atendimentos" :paginator="_paginacao" :rows="_qtdLinhas" responsiveLayout="stack">
        <Column field="inicio" header="Data" :sortable="true"></Column>
        <Column field="nome" header="Agrônomo" :sortable="true"></Column>
        <Column field="tipoAtendimento" header="Tipo atendimento" :sortable="true"></Column>
        <Column header="Fotos" :sortable="false">
            <template #body="{ data }">
                <Button 
                    title="Fotos" 
                    icon="pi pi-images" 
                    class="p-button-rounded p-button-secondary p-button-outlined"
                    :disabled="data.possuiImagem == 'Não'"
                    @click="abrirGaleria(data.idAtendimento)"
                />
            </template>
        </Column>
    </DataTable>
    
    <Galleria :value="fotos" :responsiveOptions="responsiveOptions" :showIndicators="true"
              :numVisible="9" :circular="true" :fullScreen="true" :showItemNavigators="true"
              v-model:visible="galeria" :showThumbnails="false" containerStyle="max-width: 70%">
        <template #item="slotProps">
            <img :src="slotProps.item.imagem" :alt="slotProps.item.alt" style="width: 100%; display: block" />
        </template>
        <template #thumbnail="slotProps">
            <img :src="slotProps.item.thumbnail" :alt="slotProps.item.alt" style="width: 25%; display: block;" />
        </template>
    </Galleria>
</template>

<script lang="js">
    import DossieAgriculaService from '../../service/DossieAgriculaService';
    import AtendimentoService from '../../service/AtendimentoService';

    export default {
        name: 'Atendimentos',
        emits: ["qtdAtendimento"],
        props: {
            matricula: {
                type: Number,
                required: true
            },
            dataInicio: {
                type: String,
                required: true
            },
            dataFim: {
                type: String,
                required: true
            },
            paginacao: {
                type: Boolean,
                required: false
            },
            qtdLinhas: {
                type: Number,
                required: false
            }
        },

        data() {
            return {
                ultimaDataInicio: null,
                ultimaDataFim: null,
                atendimentos: [],
                fotos: [],
                galeria: false,
                _paginacao: false,
                _qtdLinhas: 10,
                responsiveOptions: [
                    {
                        breakpoint: '1024px',
                        numVisible: 5
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 3
                    },
                    {
                        breakpoint: '560px',
                        numVisible: 1
                    }
                ],
            }
        },

        created() {
            if(this.paginacao) {
                this._paginacao = this.paginacao;
            }

            if(this.qtdLinhas) {
                this._qtdLinhas = this.qtdLinhas;
            }
            
            this.atualizarAtendimentos();
        },

        updated() {
            this.atualizarAtendimentos();
        },

        methods: {
            atualizarAtendimentos() {
                if((this.ultimaDataInicio !== this.dataInicio && this.dataInicio !== '')
                    || (this.ultimaDataFim !== this.dataFim && this.dataFim !== '')) {
                    
                        this.ultimaDataInicio = this.dataInicio;
                        this.ultimaDataFim = this.dataFim;

                        DossieAgriculaService.getAtendimentoPorPeriodo(
                            this.matricula,
                            this.dataInicio,
                            this.dataFim
                        ).then(({ data }) => {
                            if(data) {
                                this.atendimentos = data;
                            }

                            this.$emit('qtdAtendimento', this.atendimentos.length);
                        });
                }
            },

            abrirGaleria(idAtendimento) {
                AtendimentoService.getImagensPorIdAtendimento(idAtendimento)
                    .then(({ data }) => {
                        const fotos = [];

                        for(let item of data) {
                            for(let imagem of item.imagem) {
                                fotos.push({
                                    thumbnail: "data:image/jpeg;base64," + imagem,
                                    imagem: "data:image/jpeg;base64," + imagem,
                                    alt: ""
                                });
                            }
                        }

                        this.fotos = fotos;
                        this.galeria = true;
                    });
            }
        }
    }
</script>