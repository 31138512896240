import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1`;

class DossieAgriculaService {
    getAtendimentoPorPeriodo(matricula, dataInicio, dataFim) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/dossie-agricola-atendimento/do-periodo/" + matricula + "/" + dataInicio + "/" + dataFim);
    }

    getImagensPorIdServicoRealizado(idServicoRealizado) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/dossie-agricola-area-servico-realizado/imagens-servico/" + idServicoRealizado);
    }

    getImagensPorIdOcorrenciaIdentificada(idOcorrenciaIdentificada) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/dossie-agricola-area-ocorrencia-imagem/" + idOcorrenciaIdentificada);
    }

    getResumoGeral(safra, matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/dossie-agricola-resumo-geral/" + safra + "/" + matricula);
    }

    getResumoArea(idProjetoAgricola) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/dossie-agricola-resumo-area/buscarTodos/" + idProjetoAgricola );
    }

    getInformacoesDossie(safra, matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/dossie/atendimentos-realisados/" + safra + "/" + matricula);
    }

    getUnidades() {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/dossie/unidade/");
    }
}

export default new DossieAgriculaService();