<template>
    <div class="card">
        <div class="flex align-items-center">
            <img src="../../../public/if_combine-front-01_2140066.png" width="65" class="mr-2" />

            <div>
                <h5 class="mb-1">Projetos agrícolas</h5>
                <span>{{ resumoGeral.length }} projetos</span>
            </div>
        </div>

        <DataTable :value="resumoGeral" responsiveLayout="stack" id="projetos-agricolas">
            
            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column header="&nbsp;" style="width:5rem;">
                <template #body="{ data }">
                    <Button label="Detalhe" 
                            class="p-button-raised p-button-success" 
                            v-on:click="$emit('setDetalharArea', data)"
                            icon="pi pi-search" />
                </template>
            </Column>
            <Column field="idProjetoAgricola" header="Cód." style="width:3rem;" :sortable="true" />
            <Column field="safra" header="Safra" style="width:4rem;" :sortable="true" />
            <Column field="cultura" header="Cultura" style="width:8rem;" :sortable="true" />
            <Column field="nomePropriedade" header="Imóvel" style="min-width:8rem;" :sortable="true" />
            <Column field="tamanho" header="Tamanho" style="width:10rem; text-align:center;" :sortable="true">
                <template #body="{ data }">
                    {{ formatDecimal((this.unidadeMedida === 'ALQUEIRE' ? data.alqueire : data.tamanho)) }} 
                    <br />
                    {{ getDescricaoUnidadeMedida() }}
                </template>
            </Column>
            <Column field="previsao" header="Previsto" style="width:10rem; text-align:right;" :sortable="true">
                <template #body="{ data }">
                    {{ formatDecimal(data.previsao / data.divisaoScOuKg) }} {{ data.tituloScOuKg }}
                </template>
            </Column>
            <Column field="obtida" header="Obtida" style="width:10rem; text-align: right;" :sortable="true">
                <template #body="{ data }">
                    {{ formatDecimal(data.obtida / data.divisaoScOuKg) }} {{ data.tituloScOuKg }}
                </template>
            </Column>
        </DataTable>
    </div>

</template>

<script lang="js">
    import DossieAgriculaService from '../../service/DossieAgriculaService';
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ProjetosAgricolas',
        emits: ["qtdResumoGeral", "setDetalharArea"],
        props: {
            matricula: {
                type: Number,
                required: true
            },
            safra: {
                type: String,
                required: true
            },
            unidadeMedida: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                resumoGeral: [],
                ultimaSafra: null
            }
        },

        created() {
            this.atualizarResumoGeral();
        },

        updated() {
            this.atualizarResumoGeral();
        },

        methods: {
            atualizarResumoGeral() {
                if(this.ultimaSafra !== this.safra && this.safra !== '') {
                        
                        this.ultimaSafra = this.safra;

                        DossieAgriculaService.getResumoGeral(this.safra, this.matricula)
                            .then(({ data }) => {
                                this.resumoGeral = data ? data : [];
                            })
                            .catch(error => {
                                this.resumoGeral = [];
                            });
                    }

                this.$emit('qtdResumoGeral', this.resumoGeral.length);
            },

            getDescricaoUnidadeMedida() {
                if(!this.unidadeMedida) return '';
                const descricao = this.unidadeMedida.toLowerCase();
                return descricao[0].toUpperCase() + descricao.substring(1, descricao.length) + '(s)';
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            }
        }
    }
</script>

<style>
    #projetos-agricolas .p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>
